import React from 'react';
import { useListState } from 'react-stately';
import type { ListProps } from '@react-stately/list';
import ListBox from '_atoms/ListBox/ListBox';

interface IListProps<T> extends ListProps<T> {}

const List = <T extends object>(props: IListProps<T>) => {
  const state = useListState(props);

  return <ListBox state={state} />;
};

export default List;
